import React, { useState, SyntheticEvent } from "react";
import { putRequest, currentTimeUnixTimestamp } from "helpers";
import { Item as ItemType, Authorization } from "types";
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "store";
import { checkUncheckItem } from "redux/slices/checklistSlice";
import { createChange } from "create_change";
import { Draggable, DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";

export const Item = ({
  item,
  authorization,
  isTemplate,
  index,
}: {
  item: ItemType;
  authorization: Authorization;
  isTemplate: boolean;
  index: number;
}) => {
  const showMode = "showMode";
  const editNameMode = "editNameMode";
  const editCategoryMode = "editCategoryMode";
  const [mode, setMode] = useState(showMode);
  const [newName, setNewName] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const checklist = useSelector((state: RootState) => state.checklist.value);
  const currentUser = useSelector(
    (state: RootState) => state.currentUser.value,
  );
  const { isSuggestionMode, suggestionId } = useSelector(
    (state: RootState) => state.suggestion.value,
  );
  const canEditItem = authorization.can_edit_item || isSuggestionMode;

  const dispatch = useDispatch();

  const checkItem = (item: ItemType) => {
    putRequest(`/items/${item.id}/check`, {
      checked: !item.checked,
    }).then(() => {
      dispatch(
        checkUncheckItem({
          id: item.id,
          checked: !item.checked,
        }),
      );
    });
  };

  const checkboxTag = () => {
    if (!isTemplate && authorization.can_edit_item) {
      return (
        <>
          <input
            type="checkbox"
            name="check"
            id={`check-${item.id}`}
            defaultChecked={item.checked}
            className="form-check-input me-1 js-item-check"
            data-item-id={item.id}
            onChange={() => {
              checkItem(item);
            }}
          ></input>
          &nbsp;
        </>
      );
    }
  };

  const enableShowMode = () => {
    setMode(showMode);
  };

  const enableEditNameMode = (event: SyntheticEvent) => {
    event.preventDefault();

    setNewName(item.name);
    setMode(editNameMode);
  };

  const enableEditCategoryMode = (event: SyntheticEvent) => {
    event.preventDefault();

    setNewCategory(item.category);
    setMode(editCategoryMode);
  };

  const updateChecklistItem = (event: SyntheticEvent) => {
    event.preventDefault();

    createChange(
      {
        id: crypto.randomUUID(),
        author_username: currentUser.username,
        created_at: currentTimeUnixTimestamp(),
        name: "renameItem",
        checklist_id: checklist.id,
        suggestion_id: suggestionId,
        payload: {
          itemId: item.id,
          oldItemName: item.name,
          newItemName: newName,
          categoryName: item.category,
        },
      },
      dispatch,
    ).then(enableShowMode);
  };

  const updateCategoryName = (event: SyntheticEvent) => {
    event.preventDefault();

    if (item.category != newCategory.trim()) {
      createChange(
        {
          id: crypto.randomUUID(),
          author_username: currentUser.username,
          created_at: currentTimeUnixTimestamp(),
          name: "updateItemCategory",
          checklist_id: checklist.id,
          suggestion_id: suggestionId,
          payload: {
            itemId: item.id,
            itemName: item.name,
            oldCategoryName: item.category,
            newCategoryName: newCategory.trim(),
          },
        },
        dispatch,
      );
    }
    enableShowMode();
  };

  const deleteChecklistItem = (event: SyntheticEvent) => {
    event.preventDefault();
    createChange(
      {
        id: crypto.randomUUID(),
        author_username: currentUser.username,
        created_at: currentTimeUnixTimestamp(),
        name: "deleteItem",
        checklist_id: checklist.id,
        suggestion_id: suggestionId,
        payload: {
          itemId: item.id,
          itemName: item.name,
          categoryName: item.category,
        },
      },
      dispatch,
    );
  };

  const showView = (
    dragHandleProps: DraggableProvidedDragHandleProps | null,
  ) => {
    return (
      <span>
        {checkboxTag()}

        {isTemplate ? (
          item.name
        ) : (
          <label htmlFor={`check-${item.id}`}>{item.name}</label>
        )}
        {canEditItem && (
          <i className="bi-arrow-down-up" {...dragHandleProps}></i>
        )}
        {canEditItem && (
          <a onClick={enableEditNameMode} href="#" title="Edit">
            <i className="bi-pencil-square"></i>
          </a>
        )}
        {!canEditItem && <>&nbsp;</>}
      </span>
    );
  };

  const editNameView = () => {
    return (
      <form onSubmit={updateChecklistItem}>
        <div className="row mb-2 g-0">
          <div className="col-auto">
            <input
              className="form-control form-control-sm"
              type="text"
              id="item-name"
              name="name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <a
              onClick={updateChecklistItem}
              title="Save new item name"
              href="#"
            >
              <i
                className="bi-check text-success"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
            <a onClick={enableShowMode} title="Cancel editing" href="#">
              <i
                className="bi-x text-warning"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
            <a
              onClick={deleteChecklistItem}
              title="Delete item"
              href="#"
              className="me-1"
            >
              <i
                className="bi-trash text-danger"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
          </div>
          <div className="col-auto">
            <a
              href="#"
              onClick={enableEditCategoryMode}
              className="align-text-top"
            >
              Edit category
            </a>
          </div>
        </div>
      </form>
    );
  };

  const editCategoryView = () => {
    return (
      <form onSubmit={updateCategoryName}>
        <div className="row mb-2 g-0">
          <div className="col-auto">
            <label
              className="form-label align-text-top me-1"
              htmlFor={`item-${item.id}-category`}
            >
              category for {item.name}:
            </label>
          </div>
          <div className="col-auto">
            <input
              className="form-control form-control-sm"
              type="text"
              id={`item-${item.id}-category`}
              name="category"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <a
              onClick={updateCategoryName}
              title="Save new item category"
              href="#"
            >
              <i
                className="bi-check text-success"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
            <a onClick={enableShowMode} title="Cancel editing" href="#">
              <i
                className="bi-x text-warning"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
          </div>
        </div>
      </form>
    );
  };

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <li
          className="list-group-item"
          id={`item-${item.id}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {mode === showMode && showView(provided.dragHandleProps)}
          {mode === editNameMode && editNameView()}
          {mode === editCategoryMode && editCategoryView()}
        </li>
      )}
    </Draggable>
  );
};
