import React from "react";
import { Categories } from "./categories";
import NewItemForm from "./new_item_form";
import { Changes } from "./changes";
import type { RootState } from "store";
import { useSelector } from "react-redux";
import { SuggestionBar } from "./suggestion_bar";

export const ChecklistRoot = () => {
  const checklist = useSelector((state: RootState) => state.checklist.value);
  const authorization = checklist.authorization;
  const isSuggestionMode = useSelector(
    (state: RootState) => state.suggestion.value.isSuggestionMode,
  );
  const canCreateItem = authorization.can_create_item || isSuggestionMode;

  return (
    <>
      {canCreateItem && <NewItemForm />}
      <Categories />
      {isSuggestionMode && <Changes />}
      {isSuggestionMode && <SuggestionBar />}
    </>
  );
};
