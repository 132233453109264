import React, { useState, FormEvent } from "react";
import { currentTimeUnixTimestamp } from "helpers";
import type { RootState } from "store";
import { useSelector, useDispatch } from "react-redux";
import { createChange } from "create_change";

export default () => {
  const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");

  const checklist = useSelector((state: RootState) => state.checklist.value);
  const dispatch = useDispatch();

  const currentUser = useSelector(
    (state: RootState) => state.currentUser.value,
  );

  const categoriesList = checklist.categories.map((category) => (
    <option value={category.name} key={category.id}></option>
  ));

  const suggestionId = useSelector(
    (state: RootState) => state.suggestion.value.suggestionId,
  );

  const handleSubmit = (event: FormEvent) => {
    setDisabled(true);
    event.preventDefault();

    createChange(
      {
        id: crypto.randomUUID(),
        author_username: currentUser.username,
        created_at: currentTimeUnixTimestamp(),
        name: "createItem",
        checklist_id: checklist.id,
        suggestion_id: suggestionId,
        payload: {
          itemId: crypto.randomUUID(),
          itemName: name,
          categoryName: category.trim(),
        },
      },
      dispatch,
    )
      .finally(() => setName(""))
      .finally(() => setDisabled(false));
  };

  return (
    <>
      <hr />

      <form id="new_item" onSubmit={handleSubmit}>
        <h3>New item</h3>
        <div className="row mb-3">
          <label className="col-auto col-form-label" htmlFor="item_name">
            Name
          </label>
          <div className="col-auto">
            <input
              className="form-control"
              type="text"
              name="name"
              id="item_name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="row">
          <label className="col-auto col-form-label" htmlFor="category">
            Category
          </label>
          <div className="col-auto">
            <input
              type="text"
              name="category"
              id="category"
              className="form-control mb-3"
              value={category}
              list="existing-categories"
              onChange={(e) => setCategory(e.target.value)}
              disabled={disabled}
            />
            <datalist id="existing-categories">{categoriesList}</datalist>
          </div>
        </div>
        <div className="row">
          <div>
            <input
              type="submit"
              name="commit"
              value="Add Item"
              className="btn btn-primary"
              disabled={disabled}
            />
          </div>
        </div>
      </form>
    </>
  );
};
