import React from "react";
import { createRoot } from "react-dom/client";

import { ChecklistRoot } from "../checklist_page";
import "../copy_checklist_to_clipboard";

import { store } from "store";
import { Provider } from "react-redux";

document.addEventListener("DOMContentLoaded", () => {
  const reactContainer = document.getElementById("react-root-element");
  if (reactContainer) {
    const reactRootElement = createRoot(reactContainer);
    reactRootElement.render(
      <Provider store={store}>
        <ChecklistRoot />
      </Provider>,
    );
  }
});
