import React from "react";
import type { RootState } from "store";
import { useSelector } from "react-redux";
import { ChangeComponent } from "./change_component";

export const Changes = () => {
  const changes = useSelector(
    (state: RootState) => state.checklist.value.changes,
  );

  const listItems = changes.map((change) => (
    <ChangeComponent key={change.id} change={change} />
  ));

  return (
    <>
      <hr />
      <h3>Changelog</h3>
      <ul className="list-group list-group-flush">{listItems}</ul>
    </>
  );
};
