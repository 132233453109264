import { Change } from "types";
import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import {
  deleteCategory,
  deleteItem,
  moveItem,
  moveCategory,
  renameCategory,
  renameItem,
  updateItemCategory,
  createItem,
} from "redux/slices/checklistSlice";
import { postRequest } from "helpers";

export async function createChange(
  change: Change,
  dispatch: Dispatch<UnknownAction>,
) {
  if (!change.suggestion_id) {
    const requestBody = {
      id: change.id,
      checklist_id: change.checklist_id,
      author_username: change.author_username,
      created_at: change.created_at,
      name: change.name,
      payload: change.payload,
    };

    postRequest("/changes", requestBody).catch((error) => {
      console.error("Error posting request:", error);
    });
  }
  return dispatch(generateAction(change));
}

function generateAction(change: Change) {
  switch (change.name) {
    case "deleteCategory":
      return deleteCategory({
        id: change.id,
        checklistId: change.checklist_id,
        categoryName: change.payload.categoryName,
        authorUsername: change.author_username,
        suggestionId: change.suggestion_id,
      });
    case "renameCategory":
      return renameCategory({
        id: change.id,
        checklistId: change.checklist_id,
        newName: change.payload.newCategoryName,
        oldName: change.payload.oldCategoryName,
        authorUsername: change.author_username,
        suggestionId: change.suggestion_id,
      });
    case "deleteItem":
      return deleteItem({
        id: change.id,
        checklistId: change.checklist_id,
        itemId: change.payload.itemId,
        itemName: change.payload.itemName,
        categoryName: change.payload.categoryName,
        authorUsername: change.author_username,
        suggestionId: change.suggestion_id,
      });
    case "renameItem":
      return renameItem({
        id: change.id,
        checklistId: change.checklist_id,
        authorUsername: change.author_username,
        itemId: change.payload.itemId,
        oldItemName: change.payload.oldItemName,
        newItemName: change.payload.newItemName,
        categoryName: change.payload.categoryName,
        suggestionId: change.suggestion_id,
      });
    case "updateItemCategory":
      return updateItemCategory({
        id: change.id,
        checklistId: change.checklist_id,
        authorUsername: change.author_username,
        itemId: change.payload.itemId,
        itemName: change.payload.itemName,
        oldCategoryName: change.payload.oldCategoryName,
        newCategoryName: change.payload.newCategoryName,
        suggestionId: change.suggestion_id,
      });
    case "moveItem":
      return moveItem({
        id: change.id,
        checklistId: change.checklist_id,
        authorUsername: change.author_username,
        itemId: change.payload.itemId,
        itemName: change.payload.itemName,
        oldCategoryName: change.payload.oldCategoryName,
        newCategoryName: change.payload.newCategoryName,
        oldItemPosition: change.payload.oldItemPosition,
        newItemPosition: change.payload.newItemPosition,
        suggestionId: change.suggestion_id,
      });
    case "moveCategory":
      return moveCategory({
        id: change.id,
        checklistId: change.checklist_id,
        authorUsername: change.author_username,
        categoryName: change.payload.categoryName,
        oldPosition: change.payload.oldPosition,
        newPosition: change.payload.newPosition,
        suggestionId: change.suggestion_id,
      });
    case "createItem":
      return createItem({
        id: change.id,
        checklistId: change.checklist_id,
        authorUsername: change.author_username,
        itemId: change.payload.itemId,
        itemName: change.payload.itemName,
        categoryName: change.payload.categoryName,
        suggestionId: change.suggestion_id,
      });
  }
}
