import React from "react";
import { Item } from "./item";
import { Item as ItemType, Authorization } from "types";

export const Items = (props: {
  items: Array<ItemType>;
  authorization: Authorization;
  isTemplate: boolean;
  categoryId: string;
  dragNDropPlaceholder: React.ReactNode;
  droppableRef: (a?: HTMLElement | null) => void;
}) => {
  const listItems = props.items.map((item, index) => (
    <Item
      key={item.id}
      item={item}
      isTemplate={props.isTemplate}
      authorization={props.authorization}
      index={index}
    />
  ));

  return (
    <ul className="list-group list-group-flush" ref={props.droppableRef}>
      {listItems}
      {props.dragNDropPlaceholder}
    </ul>
  );
};
