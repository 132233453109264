import React, { useState, SyntheticEvent } from "react";
import { Items } from "./items";
import { currentTimeUnixTimestamp } from "helpers";
import { Category as CategoryType, Authorization } from "types";
import { useSelector, useDispatch } from "react-redux";
import type { RootState } from "store";
import { createChange } from "create_change";
import {
  Draggable,
  Droppable,
  DraggableProvidedDragHandleProps,
} from "@hello-pangea/dnd";

export const Category = (props: {
  category: CategoryType;
  authorization: Authorization;
  usesCategories: boolean;
  isTemplate: boolean;
  index: number;
}) => {
  const checklist = useSelector((state: RootState) => state.checklist.value);
  const showMode = "showMode";
  const editNameMode = "editNameMode";
  const [mode, setMode] = useState(showMode);
  const [newName, setNewName] = useState("");
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: RootState) => state.currentUser.value,
  );
  const { isSuggestionMode, suggestionId } = useSelector(
    (state: RootState) => state.suggestion.value,
  );

  const canEditCategory = () => {
    return props.authorization.can_edit_category || isSuggestionMode;
  };

  const canDestroyCategory = () => {
    return props.authorization.can_destroy_category || isSuggestionMode;
  };

  const editLink = () => {
    if (canEditCategory()) {
      return (
        <a href="#" title="Edit category" onClick={enableEditNameMode}>
          <i className="bi-pencil-square"></i>
        </a>
      );
    }
  };

  const deleteCategoryRequest = (event: SyntheticEvent) => {
    event.preventDefault();
    createChange(
      {
        id: crypto.randomUUID(),
        author_username: currentUser.username,
        created_at: currentTimeUnixTimestamp(),
        name: "deleteCategory",
        checklist_id: checklist.id,
        suggestion_id: suggestionId,
        payload: {
          categoryName: props.category.name,
          itemLength: props.category.items.length,
        },
      },
      dispatch,
    );
  };

  const updateCategoryName = (event: SyntheticEvent) => {
    event.preventDefault();

    createChange(
      {
        id: crypto.randomUUID(),
        author_username: currentUser.username,
        created_at: currentTimeUnixTimestamp(),
        name: "renameCategory",
        checklist_id: checklist.id,
        suggestion_id: suggestionId,
        payload: {
          oldCategoryName: props.category.name,
          newCategoryName: newName.trim(),
        },
      },
      dispatch,
    ).then(enableShowMode);
  };

  const destroyLink = () => {
    if (canDestroyCategory()) {
      return (
        <a
          onClick={deleteCategoryRequest}
          href="#"
          title="Delete category"
          style={{ fontSize: "1.5rem" }}
        >
          <i className="bi-trash"></i>
        </a>
      );
    }
  };

  const enableShowMode = () => {
    setMode(showMode);
  };

  const enableEditNameMode = (event: SyntheticEvent) => {
    event.preventDefault();

    setNewName(props.category.name);
    setMode(editNameMode);
  };

  const showView = (
    dragHandleProps: DraggableProvidedDragHandleProps | null,
  ) => {
    return (
      <h4 id={`category-${props.category.id}`} {...dragHandleProps}>
        {props.category.name === "" ? "other" : props.category.name}
        {editLink()}
      </h4>
    );
  };

  const editNameView = () => {
    return (
      <form onSubmit={updateCategoryName}>
        <div className="row mb-2 g-0" id={`category-${props.category.id}`}>
          <div className="col-auto">
            <input
              className="form-control form-control-sm"
              type="text"
              id="category-name"
              name="category-name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <a
              onClick={
                props.category.name === newName
                  ? enableShowMode
                  : updateCategoryName
              }
              title="Save"
              href="#"
            >
              <i
                className="bi-check text-success"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
            <a onClick={enableShowMode} title="Cancel editing" href="#">
              <i
                className="bi-x text-warning"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
            {destroyLink()}
          </div>
        </div>
      </form>
    );
  };

  return (
    <Draggable draggableId={props.category.name} index={props.index}>
      {(provided) => (
        <li
          className="list-group-item"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {props.usesCategories &&
            mode === showMode &&
            showView(provided.dragHandleProps)}
          {props.usesCategories && mode === editNameMode && editNameView()}
          <Droppable droppableId={props.category.id} type="item">
            {(provided) => (
              <Items
                items={props.category.items}
                categoryId={props.category.id}
                isTemplate={props.isTemplate}
                authorization={props.authorization}
                droppableRef={provided.innerRef}
                {...provided.droppableProps}
                dragNDropPlaceholder={provided.placeholder}
              />
            )}
          </Droppable>
        </li>
      )}
    </Draggable>
  );
};
