import { Change } from "types";

export function initSaveAllChangesWithSuggestionHandler(
  checklistId: string,
  changes: Change[],
  suggestionId: string,
) {
  return function () {
    const changesForSuggestion = changes.filter(
      (_) => _.suggestion_id == suggestionId,
    );

    localStorage.setItem(
      `suggestion-${suggestionId}`,
      JSON.stringify(changesForSuggestion.reverse()),
    );

    window.location.href = `/suggestions/new?checklist_id=${checklistId}&suggestion_id=${suggestionId}`;
  };
}
